import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

interface NavLinkProps {
  white: boolean
}

export const NavLink: React.FC<NavLinkProps & { to: string }> = ({
  children,
  white,
  to,
}) => (
  <Link
    className={
      white
        ? 'text-white hover:text-opacity-80'
        : 'text-text-primary-light hover:text-blue-600'
    }
    activeClassName="text-text-secondary-light"
    to={to}
  >
    {children}
  </Link>
)

export const NavA: React.FC<
  NavLinkProps & { className?: string; href?: string }
> = ({ children, className, white, href }) => (
  <a
    className={classNames(
      white
        ? 'text-white hover:text-opacity-80'
        : 'text-text-primary-light hover:text-blue-600',
      className
    )}
    href={href}
  >
    {children}
  </a>
)
