import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { Cross as Hamburger } from 'hamburger-react'
import classNames from 'classnames'
import icon from '../images/qualdesk-icon.svg'
import whiteIcon from '../images/qualdesk-icon-white.svg'
import logo from '../images/qualdesk-logo.svg'
import whiteLogo from '../images/qualdesk-logo-white.svg'
import { NavA, NavLink } from './nav/links'

export interface NavProps {
  homePage?: boolean
}

export const Nav: React.FC<NavProps> = ({ homePage }) => {
  const [isOpen, setOpen] = useState(false)
  const [scrollTrigger, inView, entry] = useInView()

  const whiteLinkText = !!(homePage && inView && !isOpen)

  return (
    <>
      <div ref={scrollTrigger} />
      <nav className="z-above-everything sticky top-0">
        <div
          className={classNames('relative z-10 transition-all duration-200', {
            'bg-white': isOpen || (!!entry && !inView),
            'lg:bg-transparent': isOpen,
            'border-b border-border-weak-light': !isOpen && !!entry && !inView,
            'lg:bg-white lg:border-b lg:border-border-weak-light':
              !!entry && !inView,
          })}
        >
          <div className="text-text-primary-light grid grid-cols-3 items-center mx-auto max-w-6xl h-16 whitespace-nowrap lg:h-20">
            <div
              className={classNames('justify-self-start pl-2.5 lg:hidden', {
                'text-white': whiteLinkText,
              })}
            >
              <Hamburger
                size={20}
                duration={0.2}
                label="Menu"
                toggled={isOpen}
                toggle={setOpen}
                rounded
              />
            </div>
            <Link className="justify-self-center md:hidden" to="/">
              <img
                className={classNames('w-6 h-6', {
                  'filter drop-shadow-hero': whiteLinkText,
                })}
                src={whiteLinkText ? whiteIcon : icon}
                alt="Qualdesk logo"
              />
            </Link>
            <Link
              className={classNames(
                'hidden justify-self-center md:block lg:justify-self-start lg:pl-6',
                { 'filter drop-shadow-hero': whiteLinkText }
              )}
              to="/"
            >
              <img src={whiteLinkText ? whiteLogo : logo} alt="Qualdesk logo" />
            </Link>
            <div
              className={classNames(
                'items-center justify-self-end pr-6 space-x-6 lg:flex lg:col-span-2',
                { 'filter drop-shadow-hero': whiteLinkText }
              )}
            >
              <div className="nowrap hidden space-x-10 lg:flex lg:justify-self-end">
                <NavLink to="/integrations" white={whiteLinkText}>
                  Integrations
                </NavLink>
                <NavA href={process.env.TEMPLATES_URL} white={whiteLinkText}>
                  Templates
                </NavA>
                <NavLink to="/blog" white={whiteLinkText}>
                  Blog
                </NavLink>
                <NavLink to="/pricing" white={whiteLinkText}>
                  Pricing
                </NavLink>
                <NavLink to="/release-notes" white={whiteLinkText}>
                  Release notes
                </NavLink>
                <NavA href={process.env.SIGNIN_URL} white={whiteLinkText}>
                  Log in
                </NavA>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'border-border-weak-light absolute inset-x-0 top-16 p-6 bg-white border-b transform transition-all duration-200 ease-out lg:hidden',
            {
              '-translate-y-6 opacity-0 invisible': !isOpen,
              'translate-y-0 opacity-100': isOpen,
            }
          )}
        >
          <ul className="text-center space-y-4">
            <li>
              <Link
                className="text-text-primary-light hover:text-text-secondary-light"
                activeClassName="text-text-secondary-light"
                to="/"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="text-text-primary-light hover:text-text-secondary-light"
                activeClassName="text-text-secondary-light"
                to="/integrations"
              >
                Integrations
              </Link>
            </li>
            <li>
              <a
                className="text-text-primary-light hover:text-blue-600"
                href={process.env.TEMPLATES_URL}
              >
                Templates
              </a>
            </li>
            <li>
              <Link
                className="text-text-primary-light hover:text-text-secondary-light"
                activeClassName="text-text-secondary-light"
                to="/blog"
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                className="text-text-primary-light hover:text-text-secondary-light"
                activeClassName="text-text-secondary-light"
                to="/release-notes"
              >
                Release notes
              </Link>
            </li>
            <li>
              <Link
                className="text-text-primary-light hover:text-text-secondary-light"
                activeClassName="text-text-secondary-light"
                to="/pricing"
              >
                Pricing
              </Link>
            </li>
            <li>
              <a
                className="text-text-primary-light hover:text-text-secondary-light"
                href={process.env.SIGNIN_URL}
              >
                Log in
              </a>
            </li>
          </ul>
          <a
            className="active:bg-button-primary-active-light hover:bg-button-primary-hovered-light bg-button-primary-light flex items-center justify-center mt-8 p-4 h-12 text-center text-white text-sm leading-tight border border-transparent rounded-lg"
            href={process.env.SIGNUP_URL}
          >
            <span className="-mt-0.5">Use Qualdesk for free</span>
          </a>
        </div>
      </nav>
    </>
  )
}
