import React from 'react'
import { Link } from 'gatsby'

export const FooterLinkListItem: React.FC<{ to: string }> = ({
  children,
  to,
}) => (
  <li>
    <Link className="text-text-secondary-light hover:text-blue-600" to={to}>
      {children}
    </Link>
  </li>
)
