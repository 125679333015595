/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { useStaticQuery, graphql } from 'gatsby'
import {
  IntegrationsYaml,
  File,
  UseIntegrationsQuery,
  Maybe,
} from '../../graphql-types'

/*---------------------------------------------------------------------------*/

type Integration = Pick<
  IntegrationsYaml,
  'id' | 'name' | 'title' | 'description' | 'link' | 'imageAltText'
> & {
  image?: Maybe<Pick<File, 'publicURL'>>
}

export const useIntegrations = (): Integration[] => {
  const { allIntegrationsYaml } = useStaticQuery<UseIntegrationsQuery>(
    graphql`
      query UseIntegrations {
        allIntegrationsYaml(sort: { fields: [name], order: ASC }) {
          nodes {
            id
            name
            title
            description
            link
            image {
              publicURL
            }
            imageAltText
          }
        }
      }
    `
  )
  return allIntegrationsYaml.nodes
}

export const integrationHasLink = (
  integration: Integration
): integration is Integration & { link: string } =>
  integration.link !== undefined && integration.link !== null
