import React from 'react'
import {
  LinkedinLogo,
  MediumLogo,
  TwitterLogo,
  YoutubeLogo,
} from 'phosphor-react'
import logo from '../images/qualdesk-logo.svg'
import { FooterLinkListItem } from './FooterLinkListItem'
import { integrationHasLink, useIntegrations } from '../hooks/useIntegrations'

export const Footer: React.FC = () => {
  const integrations = useIntegrations()

  return (
    <footer className="mt-8 md:m-8 md:mb-12 md:mt-16">
      <div className="border-border-weak-light md:shadow-solid mx-auto px-6 py-8 max-w-6xl bg-white border-t md:p-8 md:border md:rounded-2xl lg:p-10">
        <nav className="md:grid md:gap-10 md:grid-cols-4">
          <section>
            <h3 className="text-text-primary-light text-base font-medium tracking-tight leading-tight">
              Product
            </h3>
            <ul className="mb-8 mt-3 text-sm space-y-2">
              <FooterLinkListItem to="/integrations">
                Integrations
              </FooterLinkListItem>
              <FooterLinkListItem to="/release-notes">
                Release notes
              </FooterLinkListItem>
              <FooterLinkListItem to="/pricing">Pricing</FooterLinkListItem>
              <FooterLinkListItem to="/blog/2021/whiteboard-for-agile-coaches">
                Whiteboard for agile coaches
              </FooterLinkListItem>
              <FooterLinkListItem to="/blog/2021/whiteboard-for-software-engineers">
                Whiteboard for software engineers
              </FooterLinkListItem>
              {integrations.filter(integrationHasLink).map(({ name, link }) => (
                <FooterLinkListItem to={link} key={name}>
                  Whiteboard for {name}
                </FooterLinkListItem>
              ))}

              <li>
                <a
                  className="text-text-secondary-light hover:text-blue-600"
                  href={process.env.SIGNIN_URL}
                >
                  Log in
                </a>
              </li>
            </ul>
          </section>
          <section>
            <h3 className="text-text-primary-light text-base font-medium tracking-tight leading-tight">
              Company
            </h3>
            <ul className="mb-8 mt-3 text-sm space-y-2">
              <FooterLinkListItem to="/blog">Blog</FooterLinkListItem>
              <FooterLinkListItem to="/values">Values</FooterLinkListItem>
              <FooterLinkListItem to="/contact">Contact</FooterLinkListItem>
            </ul>
          </section>
          <section>
            <h3 className="text-text-primary-light text-base font-medium tracking-tight leading-tight">
              Legal
            </h3>
            <ul className="mb-8 mt-3 text-sm space-y-2">
              <FooterLinkListItem to="/legal/software-terms-and-conditions">
                Software terms and conditions
              </FooterLinkListItem>
              <FooterLinkListItem to="/legal/privacy-and-cookies-policy">
                Privacy and cookies policy
              </FooterLinkListItem>
              <FooterLinkListItem to="/legal/website-terms-and-conditions">
                Website terms and conditions
              </FooterLinkListItem>
            </ul>
          </section>
          <section className="md:justify-self-end md:text-right">
            <h3 className="text-text-primary-light text-base font-medium tracking-tight leading-tight">
              Follow us
            </h3>
            <ul className="mb-8 mt-3 text-sm space-y-2">
              <li>
                <a
                  className="text-text-secondary-light flex items-center hover:text-blue-600 space-x-2 md:justify-end"
                  href="https://medium.com/qualdesk"
                >
                  <MediumLogo />
                  <span>Medium</span>
                </a>
              </li>
              <li>
                <a
                  className="text-text-secondary-light flex items-center hover:text-blue-600 space-x-2 md:justify-end"
                  href="https://twitter.com/qualdesk"
                >
                  <TwitterLogo />
                  <span>Twitter</span>
                </a>
              </li>
              <li>
                <a
                  className="text-text-secondary-light flex items-center hover:text-blue-600 space-x-2 md:justify-end"
                  href="https://linkedin.com/company/qualdesk"
                >
                  <LinkedinLogo />
                  <span>LinkedIn</span>
                </a>
              </li>
              <li>
                <a
                  className="text-text-secondary-light flex items-center hover:text-blue-600 space-x-2 md:justify-end"
                  href="https://www.youtube.com/channel/UC3X9CtudmSJzIPtHUjEj0sQ"
                >
                  <YoutubeLogo />
                  <span>YouTube</span>
                </a>
              </li>
            </ul>
          </section>
        </nav>
        <div className="text-text-secondary-light flex items-end justify-between mt-20 space-x-4">
          <img src={logo} alt="Qualdesk logo" />
          <span>
            ©<span className="sr-only md:not-sr-only"> Qualdesk</span>{' '}
            {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </footer>
  )
}
