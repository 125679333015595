import React from 'react'

import { Nav } from '../components/Nav'
import { Footer } from '../components/Footer'

export const BasicLayout: React.FC = ({ children }) => (
  <main>
    <Nav />
    {children}
    <Footer />
  </main>
)
